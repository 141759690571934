import React from 'react';
import { useTranslation } from 'react-i18next';
import { motion } from 'framer-motion';
import tw from 'twin.macro';
import styled from 'styled-components';
import { css } from 'styled-components/macro'; //eslint-disable-line

import useAnimatedNavToggler from '../../helpers/useAnimatedNavToggler.js';

import logo from '../../images/logo.png';
import logo_full from 'images/logo-full.png';
import { ReactComponent as MenuIcon } from 'feather-icons/dist/icons/menu.svg';
import { ReactComponent as CloseIcon } from 'feather-icons/dist/icons/x.svg';

import ReservationButton from 'components/misc/ReservationButton.js';
import { ReservationBtnUrl } from 'Constant.js';
import useShowButtonWhenScrollDown from 'helpers/useShowButtonWhenScrollDown.js';

const Header = tw.header`
  border-b-[1px]
  fixed px-6 lg:py-2
  z-10 bg-white
  top-0 left-0 right-0    
`;
const NavHeader = tw.div`
  flex justify-between items-center max-w-screen-xl mx-auto
`;

export const NavLinks = tw.div`inline-block`;

/* hocus: stands for "on hover or focus"
 * hocus:bg-primary-700 will apply the bg-primary-700 class on hover or focus
 */
export const NavLink = tw.a`
  text-lg my-2 lg:text-sm lg:mx-6 lg:my-0
  font-semibold tracking-wide transition duration-300
  pb-1 border-b-2 border-transparent hover:border-primary-500 hocus:text-primary-500
`;

export const PrimaryLink = tw(NavLink)`
  lg:mx-0
  px-8 py-3 rounded bg-primary-500 text-gray-100
  hocus:bg-primary-700 hocus:text-gray-200 focus:shadow-outline
  border-b-0
`;

export const LogoLink = styled(NavLink)`
  ${tw`flex items-center font-black border-b-0 text-2xl! ml-0!`};
  img {
    ${tw`w-10 mr-3`}
  }
`;

export const MobileNavLinksContainer = tw.nav`flex flex-1 items-center justify-between`;
export const NavToggle = tw.button`
  lg:hidden z-20 focus:outline-none hocus:text-primary-500 transition duration-300
`;
export const MobileNavLinks = motion(styled.div`
  ${tw`lg:hidden z-10 fixed top-0 inset-x-0 mx-4 my-6 p-8 border text-center rounded-lg text-gray-900 bg-white`}
  ${NavLinks} {
    ${tw`flex flex-col items-center`}
  }
`);

export const DesktopNavLinks = tw.nav`
  hidden lg:flex flex-1 justify-between items-center
`;
const Actions = tw.div`hidden flex-row items-center justify-center lg:justify-start gap-2 lg:flex`;

const PrimaryButton = tw.button`font-bold px-8 lg:px-10 py-3 rounded bg-primary-500 text-gray-100 hocus:bg-primary-700 focus:shadow-outline focus:outline-none transition duration-300`;

export default ({
  roundedHeaderButton = false,
  logoLink,
  links,
  className,
  collapseBreakpointClass = 'lg',
}) => {
  const { t, i18n } = useTranslation();
  const defaultUrl = 'https://service.makepic.ai';
  const defaultLinks = [
    <NavLinks key={1}>
      <NavLink
        href={`${defaultUrl}/replace-product-background`}
        target="_blank"
      >
        상품 배경 생성하기
      </NavLink>
      <NavLink href={`${defaultUrl}/replace-person-background`} target="_blank">
        인물 배경 생성하기
      </NavLink>
      <NavLink href={`${defaultUrl}/change-face`} target="_blank">
        얼굴 생성하기
      </NavLink>
      <NavLink href={`${defaultUrl}/remove-background`} target="_blank">
        배경 제거하기
      </NavLink>
      <NavLink href={ReservationBtnUrl} target="_blank">
        {t('reservation_button')}
      </NavLink>
    </NavLinks>,
  ];

  const { showNavLinks, animation, toggleNavbar } = useAnimatedNavToggler();
  const collapseBreakpointCss =
    collapseBreakPointCssMap[collapseBreakpointClass];

  const defaultLogoLink = (
    <LogoLink href="/">
      <img src={logo} alt="logo" style={{ width: '130px' }} />
    </LogoLink>
  );

  const { show } = useShowButtonWhenScrollDown();

  logoLink = logoLink || defaultLogoLink;
  links = links || defaultLinks;

  return (
    <Header className={className || 'header-light'}>
      <NavHeader>
        <DesktopNavLinks css={collapseBreakpointCss.desktopNavLinks}>
          {logoLink}
          {/* {links} */}
        </DesktopNavLinks>

        <MobileNavLinksContainer
          css={collapseBreakpointCss.mobileNavLinksContainer}
        >
          {logoLink}
          <MobileNavLinks
            initial={{ x: '150%', display: 'none' }}
            animate={animation}
            css={collapseBreakpointCss.mobileNavLinks}
            onClick={(e) => toggleNavbar()}
          >
            {links}
          </MobileNavLinks>
          <NavToggle
            onClick={toggleNavbar}
            className={showNavLinks ? 'open' : 'closed'}
          >
            {showNavLinks ? (
              <CloseIcon tw="w-6 h-6 mt-4" />
            ) : (
              <MenuIcon tw="w-6 h-6 mt-0" />
            )}
          </NavToggle>
        </MobileNavLinksContainer>

        <Actions>
          {/* <PrimaryButton as="a" href="https://service.makepic.ai" target="_blank">
          {t('try_button')}
        </PrimaryButton> */}
          {show && (
            <ReservationButton>{t('reservation_button')}</ReservationButton>
          )}
        </Actions>
      </NavHeader>
    </Header>
  );
};

/* The below code is for generating dynamic break points for navbar.
 * Using this you can specify if you want to switch
 * to the toggleable mobile navbar at "sm", "md" or "lg" or "xl" above using the collapseBreakpointClass prop
 * Its written like this because we are using macros and we can not insert dynamic variables in macros
 */

const collapseBreakPointCssMap = {
  sm: {
    mobileNavLinks: tw`sm:hidden`,
    desktopNavLinks: tw`sm:flex`,
    mobileNavLinksContainer: tw`sm:hidden`,
  },
  md: {
    mobileNavLinks: tw`md:hidden`,
    desktopNavLinks: tw`md:flex`,
    mobileNavLinksContainer: tw`md:hidden`,
  },
  lg: {
    mobileNavLinks: tw`lg:hidden`,
    desktopNavLinks: tw`lg:flex`,
    mobileNavLinksContainer: tw`lg:hidden`,
  },
  xl: {
    mobileNavLinks: tw`lg:hidden`,
    desktopNavLinks: tw`lg:flex`,
    mobileNavLinksContainer: tw`lg:hidden`,
  },
};
